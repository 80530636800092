<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right" v-if="canVerb(resource, 'store')">
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddInsurancePayment(2)"
          title="Crea"
          ><b-icon icon="calendar2-event" aria-hidden="true"></b-icon> Genera
          Quietanze</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddInsurancePayment(0)"
          title="Crea con sospesi"
          ><b-icon icon="bookmark-plus" aria-hidden="true"></b-icon> Includi
          Sospesi</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddInsurancePayment(3)"
          title="Crea con acconti"
          ><b-icon icon="cash-stack" aria-hidden="true"></b-icon> Includi
          Acconti</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddInsurancePayment(1)"
          title="Crea con arretrati"
          ><b-icon icon="calendar2-plus" aria-hidden="true"></b-icon> Includi
          Arretrati</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddInsurancePayment(4)"
          title="Crea manuale"
          ><b-icon icon="receipt" aria-hidden="true"></b-icon> Crea
          Manuale</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="payment_type"
                label="Tipo"
                :options="payment_types"
                v-model="filter.byAttribute.payment_type"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="status_payment"
                label="Stato"
                :options="status_payments"
                v-model="filter.byAttribute.status_payment"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="sent"
                label="Quietanza già inviata"
                :options="[
                  { value: 'Y', text: 'Si' },
                  { value: 'N', text: 'No' },
                ]"
                v-model="filter.byAttribute.sent"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="selected"
                label="Mostra solo selezionate (per invio)"
                :options="[
                  { value: 'Y', text: 'Si' },
                  { value: 'N', text: 'No' },
                ]"
                v-model="filter.byCustom.selected"
              />
            </div>
          </b-row>

          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="periodo"
                label="Periodo da"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="periodo"
                label="Periodo a"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Produttore"
                label="Produttore"
                v-model="filter.byBrokerEnhanced.salesmen"
                :options="salesmen"
                :taggable="salesmen_taggable"
                :multiple="true"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Collaborazione"
                label="Collaborazione"
                v-model="filter.byBrokerEnhanced.cooperators"
                :options="cooperators"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="Tipo Polizza"
                label="Tipo Polizza"
                :options="risks"
                v-model="filter.byRiskType.id"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="number"
                type="text"
                label="Numero Polizza"
                v-model="filter.byInsurancePolicy.number"
                placeholder="Inserisci un numero polizza"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Compagnia"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Da Incassare"
                label="Da Incassare"
                :options="to_collect_options"
                v-model="filter.byAttribute.to_collect"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-input
                name="nominativo"
                type="text"
                label="Nominativo"
                v-model="filter.byRegistry.title"
                placeholder="Inserisci un nominativo"
              />
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Gruppo Anagrafico"
                      label="Gruppo Anagrafico"
                      v-model="form.inpt_label_group"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchGroup"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_group"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetGroupId"
                      title="Elimina Gruppo"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-group-modal
              ref="quickSearchGroup"
              @input="handleGroupModalInput"
            ></quick-search-group-modal>
            <div class="form-group col-md-3">
              <span
                v-if="group_data"
                class="info"
                v-html="toInfoData(group_data, 'registry_group_full')"
              >
              </span>
            </div>
            <div class="col-md-3">
              <base-select
                name="IBAN"
                label="SEPA SDD"
                :options="iban_options"
                v-model="filter.byBankAccount"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                :disabled="!formLoaded"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-1-inner
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>

          <b-collapse id="collapse-1-inner" class="mt-2">
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="Commenti"
                  label="Commenti"
                  :options="comments"
                  v-model="filter.byAttribute.comment"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="CercaTestoCommenti"
                  label="Cerca Testo nei Commenti"
                  v-model="filter.byCustom.comment.value"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="Frazionamento"
                  label="Frazionamento"
                  :options="payment_splitting"
                  v-model="filter.byInsurancePolicy.payment_splitting"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="Pagamento"
                  label="Stato Pagamento"
                  :options="pagamenti"
                  v-model="filter.byInsuranceAncillary.status_ancillary"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="Contraenti"
                  label="Contraenti con più scadenze nel periodo selezionato"
                  :options="contraenti"
                  v-model="filter.byRelations"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="QuietanzeModificate"
                  label="Quietanze modificate (forzature / cambio premio)"
                  :options="modificate"
                  v-model="filter.byAttribute.modified"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="anagrafica"
                  label="Tipo anagrafica"
                  :options="tipo_anagrafica"
                  v-model="filter.byRegistry.status_registry"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="related_notes"
                  label="Elenca solo quietanze con note associate"
                  :options="relatedNotesOptions"
                  v-model="filter.byRelatedTasks"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="lastInstallmentOptions"
                  label="Scadenza"
                  :options="lastInstallmentOptions"
                  v-model="filter.byLastInstallment"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="CONV"
                  label="Convenzioni"
                  v-model="filter.byInsurancePolicy.CONV"
                />
              </div>
            </b-row>
          </b-collapse>
        </b-card>
      </b-form>
    </b-collapse>
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXPOINPA',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <!-- <button-group-table></button-group-table> -->

    <b-overlay center :show="isLoading" rounded="sm">
      <div
        class="table-wrapper"
        data-type="overflow-x"
        data-table="quietanzamento"
      >
        <insurance-payments
          :onlyActions="['infomodal']"
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName"
          :filterOn="{
            byRelations: [
              'byRegistry',
              'byRegistryGroup',
              'byHistory',
              //'byTask',
              'byUser',
            ],
          }"
          :ref="tableRef"
          @rowSelector="onUpdateStatus"
          @selezionato="onUpdateSelected"
          @spunta="onSelectAll"
          @toglispunta="onUnselectAll"
          includeRowSelector
          @open_replaced_policy="openReplacedPolicyModal"
          @open_address_book="openAddressBook"
        ></insurance-payments>
      </div>
      <!-- <div class="form-group col-md-3 align-self-end"> -->
      <div class="mb-3">
        <b-button
          type="button"
          variant="lisaweb"
          class="mt-1"
          :disabled="false"
          @click="onSendCommunications"
          >Invia selezionati</b-button
        >
      </div>
      <insurance-payment-modal
        :ref="modalRef"
        v-model="frm"
        :beFormCreate="beForm['insurance_payment']"
        :beRulesCreate="beRules['insurance_payment']"
        @create="onCreate"
        @destroy="onDestroy"
      ></insurance-payment-modal>
      <address-book-edit-modal
        v-bind:resourceIds="registry_obj"
        @fetch="forcedFetch"
      >
      </address-book-edit-modal>
      <replaced-policy-detail-modal v-bind:resourceId="replaced_policy_id">
      </replaced-policy-detail-modal>

      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import InsurancePayments from "@/components/tables/InsurancePayments";
import BaseInput from "@/components/form/BaseInput";
import ExportOptions from "@/components/ExportOptions";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters, mapActions } from "vuex";
import InsurancePaymentModal from "@/components/modals/createInsurancePaymentOptionsModal";
import { prepareFilterQueryString } from "@/utils/forms";
import ReplacedPolicyDetailModal from "@/components/modals/replacedPolicyDetailModal";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { fromFirstDay, toLastDay } from "@/utils/dates";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData } from "@/utils/transforms";
import { getVirtualValue } from "@/utils/payments";
import AddressBookEditModal from "@/components/modals/addressBookEditModal";
import QuickSearchGroupModal from "@/components/modals/quickSearchGroup";

import moment from "moment";
moment.locale("it");

// NOTA:
// payment_type == 0 && payable_type == "App\\Models\\BookEntry" allora leggo "book_entry"
// payment_type != 0 && payable_type == "App\\Models\\InsuranceAncillary" allora leggo "insurance_ancillary"

export default {
  mixins: [FormMixin, ConfirmModalMixin, CustomFiltersMixin, ShowMixin],
  data() {
    return {
      modalResourceId: null,
      exportUrl: null,
      isLoading: false,
      formLoaded: false,
      notes: [],
      replaced_policy_id: null,
      old_replaced_policy_id: null,
      new_address_book_id: null,
      old_address_book_id: null,
      new_correspondence_id: null,
      old_correspondence_id: null,
      new_registry_id: null,
      old_registry_id: null,
      registry_obj: {},
      args: {},
      tableRef: "InsurancePaymentsTableRef",
      modalRef: "InsurancePaymentOptionsModal",
      // counterSelezionate: 0,
      lastCreated: null,
      filter: this.initFilter(),
      userId: this.$store.state.auth.user.id,
      group_data: null,
      form: {
        insurance_payment: {},
        inpt_label_group: "",
        quick_value: "",
      },
      beCreateForm: {},
      companies: [],
      risks: [],
      filterName: "filterInsurancePayments",
      resource: "insurance_payments",
      repository: "insurance_payment",
      fields: [
        {
          key: "selezionato",
          label: "",
          sortable: false,
          thStyle: {
            width: "2px",
          },
        },
        // {
        //   key: "rowSelector",
        //   label: "",
        //   sortable: false,
        //   thStyle: {
        //     width: "20px",
        //   },
        // },
        {
          key: "status_payment.text",
          label: "Stato",
          sortable: true,
          sortKey: "status_payment",
          thStyle: {
            width: "80px",
          },
        },
        {
          key: "anomalie",
          label: "Anomalia / Stato",
        },
        {
          key: "payment_type",
          label: this.getDictionary("payment_type", "insurance_payment"),
          sortable: false,
          thStyle: {
            width: "80px",
          },
        },
        {
          key: "referring_at",
          label: this.getDictionary("referring_at", "insurance_payment"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          thStyle: {
            width: "80px",
          },
        },
        {
          key: "payment_splitting",
          label: this.getDictionary("payment_splitting", "insurance_policy"),
          sortable: true,
          sortKey: "byInsurancePolicy.installments",
        },
        {
          key: "insurer_code",
          // label: this.getDictionary("insurer"),
          label: "Comp.",
          sortable: false,
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "number",
          label: this.getDictionary("insurance_policy"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
          thStyle: {
            width: "80px",
          },
        },
        {
          key: "branch_and_type",
          label: "Ramo/Tipo/Prod",
          sortable: false,
          thStyle: {
            width: "80px",
          },
        },
        {
          key: "produttore",
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.title",
          thStyle: {
            width: "40px",
          },
        },
        {
          key: "contraente",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
          thStyle: {
            width: "200px!important",
          },
        },
        {
          key: "address_books",
          // label: this.getDictionary("address_book"),
          label: "Recapiti Primari",
          sortable: false,
        },
        {
          key: "gruppo",
          label: "Gruppo",
          sortable: true,
          sortKey: "byRegistryGroup.title",
          thStyle: {
            width: "40px",
          },
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "insurance_payment"),
          sortable: true,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
          formatter: (value) => toLocaleCurrency(value),
        },
        {
          key: "diff",
          label: "Diff",
          sortable: true,
          sortKey: "byGross.difference",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "perc",
          label: "Perc",
          sortable: true,
          sortKey: "byGross.percentage",
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "ultimo",
          label: "Ultimo",
          sortable: true,
          sortKey: "byInsurancePolicy.gross_premium",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "coass",
          label: "COASS",
        },
        {
          key: "communication",
          label: "Canale di Comunicaz.",
          sortable: false,
          tdClass: "text-left",
          thStyle: {
            width: "80px",
            "min-width": "80px",
          },
        },
        {
          key: "commento",
          label: "Commento",
          sortable: false,
          stickyColumn: true,
        },
        {
          key: "info",
          label: "Info",
          sortable: false,
          stickyColumn: true,
          tdClass: "bg-light",
        },
      ],
      relatedNotesOptions: [
        { value: "Y", text: "Elenca solo quietanze con note associate" },
      ],
      lastInstallmentOptions: [
        { value: "yes", text: "Solo in scadenza di contratto" },
        { value: "no", text: "Solo scadenze intermedie" },
      ],
      to_collect_options: [
        { value: "Y", text: "Si" },
        { value: "N", text: "No" },
      ],
      iban_options: [
        { value: "Y", text: "SI" },
        // { value: "N", text: "NO" },
      ],
      payment_types: [
        { value: 2, text: "Quietanze" },
        { value: 0, text: "Sospesi" },
        { value: 1, text: "Titoli arretrati" },
        { value: 3, text: "Acconti" },
        { value: 4, text: "Manuale" },
      ],
      status_payments: [
        { value: "0", text: "Valido" },
        { value: "1", text: "Da Validare" },
        { value: "2", text: "Mancante" },
        { value: "3", text: "Incassato" },
        { value: "4", text: "Annullato/Stornato" },
      ],
      pagamenti: [{ value: "0", text: "solo da incassare" }],
      modificate: [{ value: "Y", text: "solo modificate" }],
      salesmen: [],
      salesmen_taggable: true,
      cooperators: [],
      payment_splitting: [
        { value: "0", text: "Unico" },
        { value: "1", text: "Mensile" },
        { value: "2", text: "Bimestrale" },
        { value: "3", text: "Trimestrale" },
        { value: "4", text: "Quadrimestrale" },
        { value: "6", text: "Semestrale" },
        { value: "12", text: "Annuale" },
      ],
      comments: [{ value: "!", text: "solo commentate" }],
      contraenti: [{ value: "byMostInExpiry", text: "solo con più scadenze" }],
      tipo_anagrafica: [
        { value: "0", text: "Persona Fisica" },
        { value: "1", text: "Persona Giuridica" },
      ],
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    // ButtonGroupTable,
    ExportOptions,
    BaseSelect,
    BaseDatepicker,
    InsurancePayments,
    InsurancePaymentModal,
    ReplacedPolicyDetailModal,
    AddressBookEditModal,
    QuickSearchGroupModal,
  },
  computed: {
    frm() {
      return this.form[this.repository];
    },
    pymnt: {
      get() {
        return this.form[this.repository].payment_type;
      },
      set(value) {
        this.form[this.repository].payment_type = value;
      },
    },
    ...mapGetters("lisacomunica_automodal", ["isShowAutoModal"]),
  },
  methods: {
    toInfoData,
    getVirtualValue,
    forcedFetch() {
      this.$refs[this.tableRef].fetch();
    },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    htmlToVNode(html) {
      // https://github.com/bootstrap-vue/bootstrap-vue/issues/4073
      // https://github.com/bootstrap-vue/bootstrap-vue/issues/3491
      return [this.$createElement("div", { domProps: { innerHTML: html } })];
    },
    initFilter() {
      let init = {
        byAttribute: {
          to_collect: null,
          sent: null, // was filterOn
          payment_type: [2],
          status_payment: "0",
          // collaborazione: null, // HIDE
          salesman: null,
          // periodo: null,
          intestatario: null,
          comment: null, // custom filter TBD TODO: abilitare disabilitare cerca testo
          // note: null, // HIDE
          convenzione: null,
          // rid_polizza: null, // HIDE
          // contraenti: null,
          modificate: null,
        },
        byCustom: {
          comment: {
            value: null,
            likewise: 4,
            filter: "byAttribute",
          },
          selected: "N",
        },
        byRelatedTasks: null,
        byCalendar: {
          from: null,
          to: null,
        },
        byInsurer: {
          id: null,
        },
        byRiskType: {
          id: null,
        },
        byRegistryGroup: {
          code: null,
        },
        byBrokerEnhanced: {
          salesmen: [],
          cooperators: [],
        },
        byRegistry: {
          id: null,
          title: null,
          status_registry: null,
        },
        byBankAccount: null,
        byLastInstallment: null,
        byInsurancePolicy: {
          number: null,
          payment_splitting: null,
          CONV: null,
        },
        byInsuranceAncillary: {
          status_ancillary: null,
        },
        byUser: {
          id: null,
        },
      };
      return init;
    },
    initDefaultFormValues() {
      this.$set(this.form[this.repository], "payment_type", []);
      this.$set(this.form[this.repository], "status_payment", null);
      this.$set(this.form[this.repository], "insurer_id", null);
      this.$set(this.form[this.repository], "insurance_ancillary", null);
      this.$set(this.form[this.repository], "at", null);
      this.$set(
        this.form[this.repository],
        "from",
        moment().format("M") === "12"
          ? moment().add(1, "months").add(1, "years")
          : moment().add(1, "months")
      );
      this.$set(this.form[this.repository], "to", null);
    },
    onSendCommunications() {
      this.saveLisacomunicaAutoModal(true);
      // UNCOMMENT to FIX and properly set the breadcrumbs: must recreate the path
      // this.emptyQueue();
      // this.notifyQueue({
      //   level: 0,
      //   path: "",
      //   text: "Comunicazioni",
      // });
      // this.notifyQueue({
      //   level: 1,
      //   path: "module.COM",
      //   text: "Lisacomunica",
      // });
      // this.notifyQueue({
      //   level: 2,
      //   path: "module.PFOLIO",
      //   text: "Clienti",
      // });
      // TODO SWITCH $router push/replace when UNCOMMENT
      this.$router.push({
        name: "communication_procedures.index",
        // name: "registries.index",
        // params: { id: 1 },
      });
      // this.shortcut(
      //   "registries.edit",
      //   this.resourceId,
      //   null,
      //   null,
      //   null,
      //   {
      //     /* fromShortcut: true */
      //   },
      //   null,
      //   null,
      //   this.$options.name
      // );
    },
    onAddInsurancePayment(type) {
      // type
      //   "0":"Sospeso",
      //   "1":"Arretrato",
      //   "2":"In Scadenza (Quietanze)"
      //   "3":"Acconti"
      //   "4":"Manuale"
      // console.info(
      //   `Creazione tipo: ${type} (${
      //     this.payment_types.find((e) => e.value == type).text
      //   })`
      // );
      // NOTA: resetForm: vue-monthly-picker non funziona più quando il suo v-model ha un oggetto (moment) impostato.
      // bisogna forzare il reset del v-model, in questo caso dei campi "from", "to", "at" del form
      this.resetForm("form", "observer", [], ["to", "from", "at"]);
      this.initDefaultFormValues();
      this.pymnt = type;
      // seleziona tutte le compagnie per default, se type 0
      if (type === 2) {
        this.$refs[this.modalRef].selectAllInsurers();
      }
      this.$bvModal.show("createInsurancePaymentOptionsModal");
    },
    onDestroy(payload) {
      this.$bvModal.hide("createInsurancePaymentOptionsModal");
      payload.at = fromFirstDay(payload.at);
      this.showConfirm({
        yesCallback: () => {
          this.destroyPayment(payload);
        },
        noCallback: null,
        title: "Conferma",
        message: "Procedere con l'eliminazione?",
        yesLabel: "PROCEDI",
        yesVariant: "outline-lisaweb",
        noLabel: "ANNULLA",
      });
    },
    destroyPayment(payload) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.bulk_destroy(payload)
        .then((response) => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: ${
              response?.data > 0
                ? "Movimenti eliminati con successo"
                : "Non sono stati trovati movimenti da eliminare"
            }`,
          });
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onCreate() {
      this.showConfirm({
        yesCallback: () => {
          this.storePayment();
        },
        // noCallback: () => {
        //   this.$bvModal.hide("createInsurancePaymentOptionsModal");
        // },
        noCallback: null,
        title: "Conferma",
        message: `${
          this.pymnt === 2
            ? "Procedere con la creazione del quietanzamento"
            : "Procedere con l'inclusione dei movimenti selezionati?"
        }`,
        yesLabel: "PROCEDI",
        yesVariant: "outline-lisaweb",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    storePayment() {
      let tableRef = this.$refs[this.tableRef];
      this.isLoading = true;
      this.$bvModal.hide("createInsurancePaymentOptionsModal");
      // from potrebbe essere vuoto => 01/01/1900
      if (!this.form[this.repository].from) {
        this.form[this.repository].from = "1900-01-01";
      }
      // let id;
      let e, s, s1, s2;
      // prepare the period
      switch (this.pymnt) {
        case 0:
        case 1:
        case 3:
          // "Mese" fa 2 cose:
          e = toLastDay(this.form[this.repository].from);
          s1 = fromFirstDay(this.form[this.repository].from);
          s2 = this.form[this.repository].to
            ? fromFirstDay(this.form[this.repository].to)
            : "1900-01-01";
          // - invia il giorno AL (ultimo giorno del mese scelto) nel periodo già inviato
          this.form[this.repository].to = e;
          // - invia a parte il primo giorno del mese come periodo (nuovo parametro da #185)
          this.form[this.repository].at = s1;
          // "Fino a" diventa "escludi antecedenti al" e invia il primo giorno del mese scelto come DAL (nel periodo di ricerca per BE).
          this.form[this.repository].from = s2;
          // remove forbidden fields
          this.$delete(this.form[this.repository], "insurance_ancillary");
          break;
        case 2:
          // c'è solo 1 datepicker => imposto from e to rispettivamente con 1° e ultimo giorno del mese selezionato
          // add 1st day of month to "from", last day of month to "to"
          s = fromFirstDay(this.form[this.repository].from);
          e = toLastDay(this.form[this.repository].from);
          // set the calculated form values
          this.form[this.repository].from = s;
          this.form[this.repository].to = e;
          // remove forbidden fields
          this.$delete(this.form[this.repository], "insurance_ancillary");
          this.$delete(this.form[this.repository], "at");
          break;
        case 4: // CHECK the correct type id
          // Invia a BE
          // - ID titolo
          // CHECK come inviare this.form[this.repository].insurance_ancillary_id ???
          // this.form[this.repository].insurance_ancillary = {
          //   [this.form[this.repository].insurance_ancillary_id]: {},
          // };
          // this.form[this.repository].insurance_ancillary =
          //   this.form[this.repository].insurance_ancillary_id;
          // - il primo giorno del mese scelto come periodo (nuovo parametro "data di periodo" da #185)
          s1 = fromFirstDay(this.form[this.repository].from);
          this.form[this.repository].at = s1;
          // remove forbidden fields
          this.$delete(this.form[this.repository], "to");
          this.$delete(this.form[this.repository], "from");
          break;
        default:
          // ci sono 2 datepicker, in from metto 1° giorno del datepicker #1, in to metto ultimo del datepicker #2
          s = fromFirstDay(this.form[this.repository].from);
          e = toLastDay(this.form[this.repository].to);
          // set the calculated form values
          this.form[this.repository].from = s;
          this.form[this.repository].to = e;
          break;
      }
      let records = 0;
      this.store(this.repository)
        .then((response) => {
          records = response.data.data.length;
          // this.onSearch(this.filterName);
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.removePaginationByName(name);
          tableRef.currentPage = 1;
          tableRef
            .fetch()
            .then(() => {
              tableRef
                .loadSelectedCounter()
                .then(() => {
                  // this.isLoading = false;
                  this.getLastCreated()
                    .then(() => {
                      this.$showSnackbar({
                        preset: "success",
                        text: `${
                          records
                            ? this.pymnt === 2
                              ? "Quietanzamento creato con successo"
                              : "Inclusione movimenti completata"
                            : this.pymnt === 2
                            ? "Nessuna nuova quietanza generata"
                            : "Non sono stati trovati movimenti da includere"
                        }`,
                      });
                    })
                    .catch((error) => {
                      let errMsg = this.$getErrorMessage(error);
                      this.$showSnackbar({ preset: "error", text: errMsg });
                    })
                    .finally(() => {
                      this.isLoading = false;
                    });
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.$refs[this.modalRef].close();
          this.isLoading = false;
        });
    },
    onSelectAll() {
      this.filterUpdate("spunta");
    },
    onUnselectAll() {
      this.filterUpdate("toglispunta");
    },
    filterUpdate(mode) {
      let filter;
      let queryString;
      let qs = {};
      let payload = {
        related: {
          users: {
            [this.userId]: {
              selected: null,
            },
          },
        },
      };
      let tableRef = this.$refs[this.tableRef];
      const rgx = /(byAttribute\[status_payment\]=.{1})/;
      switch (mode) {
        case "spunta":
          payload.related = { users: { [this.userId]: { selected: "Y" } } };
          filter = tableRef.loadFilter(tableRef.filterName);
          if (filter) {
            qs.filter = filter;
            if (qs.filter.indexOf("byAttribute[status_payment]") >= 0) {
              qs.filter = qs.filter.replace(
                rgx,
                "byAttribute[status_payment]=0"
              );
            } else {
              qs.filter += "&byAttribute[status_payment]=0";
            }
            queryString = Object.values(qs).join("&");
          } else {
            let criteria = this.filter;
            queryString = prepareFilterQueryString(criteria);
            queryString += "byAttribute[status_payment]=0";
          }
          break;
        case "toglispunta":
          payload.related = { users: { [this.userId]: { selected: "N" } } };
          // qs.filterOn = tableRef.prepareFilterQueryString({
          //   byAttribute: { sent: "N" },
          // });
          qs.filterOn = tableRef.prepareFilterQueryString({});
          queryString = Object.values(qs).join("&");
          break;

        default:
          break;
      }
      let Repo = RepositoryFactory.get(this.repository);
      this.isLoading = true;
      Repo.filter_update(payload, queryString)
        .then(() => {
          // do not fetch, just loadSelectedCounter()
          tableRef.loadSelectedCounter();
          // then set all checkboxes of all items in this page
          tableRef.items.forEach((item) => {
            item.selected.value = payload.related.users[this.userId].selected;
          });
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Spunte ${
              mode == "spunta" ? "aggiunte" : "tolte"
            } con successo`,
          });
          // this.$refs[this.tableRef].fetch();
          this.onSearch(this.filterName);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdateSelected(row, checkboxElement) {
      this.updateSelected(row, checkboxElement);
    },
    updateSelected(row, checkboxElement) {
      // console.log("updateSelected", checkboxElement);
      let queryString = `byAttribute[id]=${row.id}`;
      let payload = {
        related: {
          users: {
            [this.userId]: {
              selected: row.selected.value,
            },
          },
        },
      };
      let tableRef = this.$refs[this.tableRef];
      let Repo = RepositoryFactory.get(this.repository);
      console.log("payload: ", payload);
      Repo.filter_update(payload, queryString)
        .then(() => {
          // this.$showSnackbar({
          //   preset: "success",
          //   text: `Aggiornato con successo`,
          // });
          // do not fetch, just loadSelectedCounter()
          tableRef.loadSelectedCounter();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          if (checkboxElement && checkboxElement.previousElementSibling) {
            checkboxElement.previousElementSibling.classList.add("hide-it");
            checkboxElement.classList.remove("hide-it");
          }
        });
    },
    onUpdateStatus(row) {
      let actualText = this.status_payments.find(
        (el) => el.value == row.status_payment.value
      ).text;
      let newText = this.status_payments.find(
        (el) => el.value != row.status_payment.value
      ).text;
      const policy_id = row?.payable?.insurance_policy?.id;
      const status_payment = row?.status_payment?.value;
      const status_policy_id =
        row?.payable?.insurance_policy?.status_policy?.value;
      let new_policy;
      let msg = `Cambiare stato da: ${actualText} a: ${newText}?`;
      if (policy_id && status_payment === 1 && status_policy_id === 3) {
        this.isLoading = true;
        let filter = `relations/byBroker/byCollaborator/byRegistry/byInsurer/byInsurerParticipant/byInsuranceRisk/byNewPolicy/byOldPolicy`;
        let queryString = `${filter ? filter : ""}`;
        this.fetchShowForm("insurance_policy", policy_id, queryString)
          .then((response) => {
            const data = response.data.data;
            new_policy = data.new_policies[0];
            if (new_policy) {
              msg = `
                  <div class="custom-grid-wrapper">
                    <p style="background: #E1DDDA;padding: 4px 8px;grid-column: 1 / span 2;margin: 0;"><strong style="text-transform: uppercase;">Vuoi procedere con il cambio di stato di questa quietanza?</strong></p>
                    <strong>Cambio stato</strong><span>Da: ${
                      actualText ?? "-"
                    } a: ${newText ?? "-"}</span>
                    <strong>Compagnia</strong><span>${
                      data?.insurer?.code ?? "-"
                    }</span>
                    <strong>Polizza di riferimento</strong><span>${
                      data?.number ?? "-"
                    }</span>
                    <strong>Errore riscontrato</strong><span>${getVirtualValue(
                      "errors",
                      row
                    )}</span>
                    <strong>Intestatario</strong><span>${
                      data?.registry?.formatted_title ?? "-"
                    }</span>
                    <strong>Scadenza</strong><span>${
                      data?.expired_at
                        ? moment(data.expired_at).format("DD/MM/YYYY")
                        : "-"
                    }</span>
                    <strong>Premio</strong><span>${
                      data?.gross_premium
                        ? toLocaleCurrency(data.gross_premium)
                        : "-"
                    }</span>
                    <p style="background: #E1DDDA;padding: 4px 8px;grid-column: 1 / span 2;margin: 0;"><strong style="text-transform: uppercase;">L'avviso di pagamento verrà creato per la seguente polizza sostituente</strong></p>
                    <strong>Compagnia</strong><span>${
                      new_policy?.insurer?.code ?? "-"
                    }</span>
                    <strong>Numero polizza</strong><span>${
                      new_policy?.number ?? "-"
                    }</span>
                    <strong>Contraente</strong><span>${
                      new_policy?.registry?.formatted_title ?? "-"
                    }</span>
                    <strong>Produttore</strong><span>${
                      new_policy?.broker_id
                        ? this.salesmen.find(
                            (e) => e.value == new_policy.broker_id
                          ).text
                        : "-"
                    }</span>
                    <strong>Ramo-Rischio</strong><span>${
                      new_policy?.insurance_risk?.risk_branch?.code ?? "-"
                    }-${new_policy?.insurance_risk?.code ?? "-"}</span>
                    <strong>Stato</strong><span>${
                      new_policy?.status_policy?.text ?? "-"
                    }</span>
                    <strong>Data decorrenza contratto</strong><span>${
                      new_policy?.effective_at
                        ? moment(new_policy.effective_at).format("DD/MM/YYYY")
                        : "-"
                    }</span>
                    <strong>Data scadenza contratto</strong><span>${
                      new_policy?.expired_at
                        ? moment(new_policy.expired_at).format("DD/MM/YYYY")
                        : "-"
                    }</span>
                    <strong>Data di copertura</strong><span>${
                      new_policy?.covered_at
                        ? moment(new_policy.covered_at).format("DD/MM/YYYY")
                        : "-"
                    }</span>
                    <strong>Data sospensione</strong><span>${
                      new_policy?.suspended_at
                        ? moment(new_policy.suspended_at).format("DD/MM/YYYY")
                        : "-"
                    }</span>
                    <strong>Rateazione</strong><span>${
                      new_policy?.payment_splitting?.text ?? "-"
                    }</span>
                    <strong>Tacito rinnovo</strong><span>${
                      new_policy?.attributables?.RENW ?? "-"
                    }</span>
                    <strong>Indicatore di polizza disdetta</strong><span>${
                      new_policy?.attributables?.LNOT ?? "-"
                    }</span>
                    <strong>Premio polizza</strong><span>${
                      new_policy?.gross_premium
                        ? toLocaleCurrency(new_policy.gross_premium)
                        : "-"
                    }</span>
                  </div>
                `;
              msg = this.htmlToVNode(msg);
            }
            this.showConfirm({
              yesCallback: () => {
                this.updateStatus(row);
              },
              noCallback: null,
              title: "Conferma",
              message: msg,
              yesLabel: "CAMBIA",
              yesVariant: "outline-lisaweb",
              noLabel: "ANNULLA",
              size: "md",
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.showConfirm({
          yesCallback: () => {
            this.updateStatus(row);
          },
          noCallback: null,
          title: "Conferma",
          message: msg,
          yesLabel: "CAMBIA",
          yesVariant: "outline-lisaweb",
          noLabel: "ANNULLA",
        });
      }
      // let msg = `Cambiare stato da: ${actualText} a: ${newText}?`;
      // msg += `<p><strong>test</strong><br />questo è un testo.</p>`;
      // msg = this.htmlToVNode(msg);
      // this.showConfirm({
      //   yesCallback: () => {
      //     this.updateStatus(row);
      //   },
      //   noCallback: null,
      //   title: "Conferma",
      //   message: msg,
      //   yesLabel: "CAMBIA",
      //   yesVariant: "outline-lisaweb",
      //   noLabel: "ANNULLA",
      //   // yesVariant:
      //   // noVariant:
      //   // headerBgVariant:
      // });
    },
    updateStatus(row) {
      let form = {
        status_payment: null,
        modified: "Y",
      };
      switch (row.status_payment.value) {
        case 0:
          form.status_payment = 1;
          break;
        case 1:
        case 2:
          form.status_payment = 0;
          break;
      }
      console.log("Form: ", form);
      let tableRef = this.$refs[this.tableRef];
      this.update(this.repository, row.id, form)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Stato cambiato con successo`,
          });
          // do not fetch, just loadSelectedCounter()
          // set the status_payment.value to update the icon and also "Stato" field's text
          tableRef.items.find((item) => item.id == row.id).modified.value = "Y";
          tableRef.items.find(
            (item) => item.id == row.id
          ).status_payment.value = form.status_payment;
          tableRef.items.find((item) => item.id == row.id).status_payment.text =
            this.status_payments.find(
              (status_payment) => status_payment.value == form.status_payment
            ).text;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    getLastCreated() {
      const Repo = RepositoryFactory.get("insurance_payment");
      return Repo.index("byLastCreated").then((response) => {
        this.lastCreated =
          response.data && response.data.data.length
            ? response.data.data[0].last_created
            : null;
        // set period
        // this.period = moment(this.lastCreated);
        // set from and to
        this.filter.byCalendar.from = fromFirstDay(this.lastCreated);
        this.filter.byCalendar.to = toLastDay(this.lastCreated);
      });
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    manipolaItems(data) {
      data.forEach((item) => {
        if (!item.selected) {
          item["selected"] = { text: "", value: null };
        }
        if (item.users.length) {
          item.selected.value = "Y";
          item.selected.text = "Si";
        } else {
          item.selected.value = "N";
          item.selected.text = "No";
        }
      });
      return data;
    },
    onSearch(name) {
      this.filter.byUser.id =
        this.filter?.byCustom?.selected === "Y"
          ? this.$store.state.auth.user.id
          : null;
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      /* */
      criteria = {};
      ["quickSearchGroup"].forEach((element) => {
        criteria[element] = {
          label: "",
          formLabel: "",
          data: null,
          formData: null,
        };
      });
      criteria.quickSearchGroup.label = this.form.inpt_label_group;
      criteria.quickSearchGroup.formLabel = "form.inpt_label_group";
      criteria.quickSearchGroup.data = this.group_data;
      criteria.quickSearchGroup.formData = "group_data";
      /* */
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      // args
      this.args = {
        items: { handle: null, params: [], filtered: false },
        verb: {},
      };
      this.args.items.handle = this.manipolaItems;
      this.$refs[this.tableRef].fetch(this.args).then(() => {
        // this.removeFilterByName(name); // WARNING: need to keep the filter because of filter_update must preserve the selection
        this.$refs[this.tableRef].loadSelectedCounter();
      });
    },
    onClearFilter(name) {
      this.resetGroupId();
      this.resetFilter();
      this.getLastCreated(); // set from e to
      // this.period = null; // month-picker
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onClearPeriod() {
      // TODO: quando wallace avrà deciso il nome del campo per byLastcreated..
      // this.showConfirm({
      //   yesCallback: () => {
      //     this.period = null;
      //   },
      //   noCallback: () => {
      //     this.period = this.lastCreated;
      //   },
      //   title: "Conferma Reset",
      //   message: `Pulire il campo o ripristinare al valore di default (${moment(
      //     this.lastCreated
      //   ).format("MM/YYYY")})?`,
      //   yesLabel: "PULISCI",
      //   noLabel: "RIPRISTINA",
      //   yesVariant: "outline-success",
      //   noVariant: "outline-lisaweb",
      //   // headerBgVariant:
      // });
    },
    openReplacedPolicyModal(policy_id) {
      this.old_replaced_policy_id = this.replaced_policy_id;
      this.replaced_policy_id = policy_id;
      if (this.old_replaced_policy_id === this.replaced_policy_id) {
        this.$bvModal.show("replacedPolicyDetailModal");
      }
    },
    openAddressBook(row) {
      let found;
      this.old_address_book_id = this.new_address_book_id;
      const address_books =
        row?.item?.payable?.insurance_policy?.registry?.address_books;
      if (address_books && address_books?.length) {
        found = address_books.find((e) => e.status_addressbook.value === 0);
        if (found) this.new_address_book_id = found.id;
      }
      this.old_correspondence_id = this.new_correspondence_id;
      const correspondences =
        row?.item?.payable?.insurance_policy?.registry?.correspondences;
      if (correspondences && correspondences?.length) {
        found = correspondences.find(
          (e) => e.status_correspondence.value === 1
        );
        if (found) this.new_correspondence_id = found.id;
      }
      this.old_registry_id = this.new_registry_id;
      this.new_registry_id = row?.item?.payable?.insurance_policy?.registry?.id;
      this.registry_obj = {
        registry_id: this.new_registry_id,
        address_book_id: this.new_address_book_id,
        correspondence_id: this.new_correspondence_id,
      };
      if (this.old_registry_id === this.new_registry_id) {
        this.$bvModal.show("addressBookEditModal");
      }

      // this.resourceId = row
      // this.$bvModal.show("addressBookEditModal");
    },
    handleGroupModalInput(value) {
      this.filter.byRegistryGroup.code = value.code;
      this.group_data = value;
      this.form.inpt_label_group = this.toInfoData(
        this.group_data,
        "registry_group"
      );
      this.$bvModal.hide("quickSearchGroupModal");
    },
    openQuickSearchGroup() {
      this.filter.byRegistryGroup.code = null;
      this.$bvModal.show("quickSearchGroupModal");
    },
    resetGroupId() {
      this.group_data = null;
      this.form.inpt_label_group = null;
      this.filter.byRegistryGroup.code = null;
    },
    // ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    // onSaveFilter(name, criteria) {
    //   console.debug(`onSaveFilter criteria: ${JSON.stringify(criteria)}`);
    //   this.saveFilterByName({ name, criteria });
    // },
    ...mapActions("lisacomunica_automodal", ["saveLisacomunicaAutoModal"]),
    //Getters
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getRisks: "risks",
      getSalesmen: "salesmen",
      // getCooperators: "cooperators",
    }),
  },
  mounted() {
    this.companies = this.getInsurers();
    this.risks = this.getRisks();
    this.risks.sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
    this.salesmen = this.getSalesmen();
    // this.cooperators = this.getCooperators();
  },
  beforeMount() {
    this.initDefaultFormValues();
    this.fetchCreateForm(this.repository)
      .then(() => {
        // set select options:
        // this.payment_types = this.beForm[this.repository].payment_type.options; // li vogliono hard-coded
        // also load counter
        this.$refs[this.tableRef].loadSelectedCounter();
        // TODO: quando wallace avrà deciso il nome del campo per byLastcreated..
        // and also byLastCreated
        this.getLastCreated()
          .then(() => {
            this.formLoaded = true; // TODO toglierlo dal finally
          })
          .catch(() => {});
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.formLoaded = true;
      });
  },
  // watch: {
  //   [this.isLoading]: {
  //     handler: function (value) {
  //       console.debug("currentPage:", value, this.currentPage);
  //       console.debug("perPage:", this.perPage);
  //       this.canSearch = value
  //     },
  //   },
  // },
};
</script>
<style lang="scss">
[data-table="quietanzamento"] .row:has(.pagination) {
  margin: 0;
}
</style>
