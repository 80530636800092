<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <span style="position: relative; top: -2px"
          ><base-icon name="address_book" width="18" height="18"
        /></span>
        <span>Modifica Recapiti Primari</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <div class="mx-1">
      <b-overlay center :show="isLoading" rounded="sm">
        <div v-if="!isLoading">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-row>
                <div class="form-group col-md-12">
                  <p>
                    Contraente:
                    <span v-html="registryName(beForm['registry'])"></span>
                  </p>
                </div>
                <div class="form-group col-md-12">
                  <b-button-toolbar>
                    <b-button-group>
                      <base-input
                        :name="beForm[rep].attribute_CELL_value.label"
                        vid="attribute_CELL_value"
                        :label="beForm[rep].attribute_CELL_value.label"
                        v-model="form[rep].attribute_CELL_value"
                        placeholder="Inserisci un numero di cellulare"
                        :rules="{ regex: /^\+?[0-9]+$/ }"
                      />
                    </b-button-group>
                    <b-button
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetAddressBookField('CELL')"
                      title="Elimina Numero di Cellulare"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-toolbar>
                </div>
                <div class="form-group col-md-12">
                  <b-button-toolbar>
                    <b-button-group>
                      <base-input
                        :name="beForm[rep].attribute_MAIL_value.label"
                        vid="attribute_MAIL_value"
                        :label="beForm[rep].attribute_MAIL_value.label"
                        v-model="form[rep].attribute_MAIL_value"
                        placeholder="Inserisci un'indirizzo e-mail"
                        :rules="{ email: true }"
                      />
                    </b-button-group>
                    <b-button
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetAddressBookField('MAIL')"
                      title="Elimina Indirizzo E-mail"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-toolbar>
                </div>
                <!-- -->
                <!-- -->
                <div class="form-group col-md-12">
                  <base-input
                    vid="attribute_NAME2_value"
                    :name="beForm['correspondence'].attribute_NAME2_value.label"
                    :label="
                      beForm['correspondence'].attribute_NAME2_value.label
                    "
                    v-model="form['correspondence'].attribute_NAME2_value"
                    placeholder="Inserisci il nome "
                    :rules="getRules('attribute_NAME2_value')"
                  />
                </div>
                <div :class="`form-group col-md-${isForeign ? '12' : '6'}`">
                  <base-select
                    vid="country"
                    name="country"
                    label="Nazione"
                    v-model="form['correspondence'].attribute_NOR_value"
                    :options="optCountries"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    @select="onSelectCountry"
                  />
                </div>
                <template v-if="!isForeign">
                  <div class="form-group col-md-6">
                    <base-select
                      name="Provincia"
                      vid="province"
                      label="Provincia"
                      v-model="form.province"
                      :options="optProvinces"
                      :taggable="false"
                      :multiple="false"
                      :closeOnSelect="true"
                      @select="onSelectProvince"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <base-select
                      vid="attribute_CITY_value"
                      :name="
                        beForm['correspondence'].attribute_CITY_value.label
                      "
                      :label="
                        beForm['correspondence'].attribute_CITY_value.label
                      "
                      v-model="form['correspondence'].attribute_CITY_value"
                      :options="optCouncils"
                      :taggable="false"
                      :multiple="false"
                      :closeOnSelect="true"
                      :rules="getRules('attribute_CITY_value')"
                    />
                  </div>
                </template>
                <div class="form-group col-md-6" v-else>
                  <base-input
                    vid="attribute_CITY2_value"
                    :name="beForm['correspondence'].attribute_CITY2_value.label"
                    :label="
                      beForm['correspondence'].attribute_CITY2_value.label
                    "
                    v-model="form['correspondence'].attribute_CITY2_value"
                    placeholder="Inserisci il comune estero "
                    :rules="getRules('attribute_CITY2_value')"
                  />
                </div>
                <div class="form-group col-md-6">
                  <base-input
                    vid="attribute_ZIPC_value"
                    :name="beForm['correspondence'].attribute_ZIPC_value.label"
                    :label="beForm['correspondence'].attribute_ZIPC_value.label"
                    v-model="form['correspondence'].attribute_ZIPC_value"
                    placeholder="Inserisci il CAP"
                    :rules="getRules('attribute_ZIPC_value')"
                  />
                </div>
                <div class="form-group col-md-12">
                  <base-input
                    vid="attribute_ADDR_value"
                    :name="beForm['correspondence'].attribute_ADDR_value.label"
                    :label="beForm['correspondence'].attribute_ADDR_value.label"
                    v-model="form['correspondence'].attribute_ADDR_value"
                    placeholder="Inserisci un indirizzo"
                    :rules="getRules('attribute_ADDR_value')"
                  />
                </div>
                <!-- -->
                <div class="form-group col-md-12">
                  <p>
                    Gruppo Primario:
                    <span v-if="primaryGroupHasLoaded">{{
                      primaryGroup.length ? primaryGroup : "-"
                    }}</span>
                    <base-icon v-else name="loading" width="15" height="15" />
                  </p>
                </div>
                <div
                  class="form-group col-md-12"
                  :data-disabletc="disableThoughCoordinator"
                >
                  <base-select
                    vid="attribute_CHAN_value"
                    :name="beForm['registry'].attribute_CHAN_value.label"
                    label="Canale Preferito"
                    v-model="form['registry'].attribute_CHAN_value"
                    :options="beForm['registry'].attribute_CHAN_value.options"
                    :rules="getRules('attribute_CHAN_value')"
                    :readonly="!primaryGroupHasLoaded"
                    :disabled="!primaryGroupHasLoaded"
                  />
                </div>
              </b-row>
              <b-row>
                <b-col align="right">
                  <b-button
                    @click="handleSubmit(saveAddressBookDetail)"
                    type="button"
                    :disabled="invalid"
                    variant="outline-lisaweb"
                    size="sm"
                    class="mt-2 float-right"
                  >
                    Salva
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
        <template #overlay>
          <div class="text-center mt-6">
            <base-icon name="loading" width="35" height="35" />
            <p id="cancel-label">Operazione in corso...</p>
          </div>
        </template>
      </b-overlay>
    </div>
    <!-- <div class="tableFixHead">ASD: {{ id }}</div> -->
  </b-modal>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
// import BaseDatepicker from "@/components/form/BaseDatepicker";
// import BaseCurrency from "@/components/form/BaseCurrency";
import FormMixin from "@/mixins/FormMixin";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
// import client from "@/repositories/api";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [FormMixin, AddressBookCorrespondenceMixin, StorageGetterMixin],
  name: "addressBookEdit",
  data() {
    return {
      repository: "address_book",
      resource: "address_book",
      isLoading: true,
      formLoaded: false,
      oldResourceIdVal: null,
      newResourceIdVal: null,
      primaryGroup: "",
      primaryGroupHasLoaded: false,
      hasPrimaryGroupWithCoordinator: false,
      disableThoughCoordinator: false,
      defaultCountryName: "Italia",
      foreignCouncilName: "Comune estero",
      optCountries: null,
      optProvinces: null,
      optCouncils: null,
      // showForm: false,
      form: {
        province: null,
        registry: {
          attribute_CHAN_value: null,
        },
        address_book: {
          attribute_CELL_value: null,
          attribute_MAIL_value: null,
        },
        correspondence: {
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    // BaseDatepicker,
    // BaseCurrency,
  },
  props: {
    items: Array,
    // resourceId: Number,
    resourceIds: {
      type: Object,
    },
    id: {
      type: String,
      default: "addressBookEditModal",
    },
  },
  watch: {
    resourceIds: function (newVal, oldVal) {
      console.log(
        `Prop "resourceIds" changed: , ${newVal}, | was: , ${oldVal}`
      );
      this.oldResourceIdVal = oldVal;
      this.newResourceIdVal = newVal;
      this.$bvModal.show("addressBookEditModal");
      this.isLoading = true;
      if (newVal) {
        this.initDefaultFormValues();
        this.fetch();
      }
    },
    // resourceId: function (newVal, oldVal) {
    //   console.log(`Prop "resourceId" changed: , ${newVal}, | was: , ${oldVal}`);
    //   this.oldResourceIdVal = oldVal;
    //   this.newResourceIdVal = newVal;
    //   this.$bvModal.show("addressBookEditModal");
    //   this.isLoading = true;
    //   if (newVal) this.fetch();
    // },
  },
  methods: {
    resetAddressBookField(attribute) {
      this.isLoading = true;
      console.log("attribute", attribute);
      this.form[this.rep][`attribute_${attribute}_value`] = null;
      const RepoAddrBook = RepositoryFactory.get(this.rep);
      RepoAddrBook.delete_attribute(
        this.newResourceIdVal.address_book_id,
        attribute
      )
        .then((response) => {
          console.log("response", response);
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          this.isLoading = false;
          this.formLoaded = true;
        });
    },
    initDefaultFormValues() {
      this.optCountries = this.countries();
      this.form["correspondence"].attribute_NOR_value = this.optCountries.find(
        (c) => c.text == this.defaultCountryName
      ).value;
      this.optProvinces = this.provinces()(
        this.form["correspondence"].attribute_NOR_value
      );
      this.optCouncils = [];
    },
    registryName(beFormRegistry) {
      const registryType = beFormRegistry?.status_registry.value;
      let ret = "-";
      if (registryType === 0) {
        // 0: Persona fisica
        ret = `${beFormRegistry.attribute_SURN_value.value} ${beFormRegistry.attribute_NAME_value.value}`;
      } else if (registryType === 1) {
        // 1: Persona giuridica
        ret = `${beFormRegistry.attribute_CNAM_value.value}`;
      }
      return ret;
    },
    saveAddressBookDetail() {
      this.isLoading = true;
      let promises = [];
      const mail = this.form[this.rep].attribute_MAIL_value;
      const cell = this.form[this.rep].attribute_CELL_value;
      // promises.push(
      //   this.editSection(
      //     "address_book",
      //     this.newResourceIdVal.address_book_id,
      //     this.repository
      //   )
      // );
      const add_book_payload = {
        registry: {
          // "5350": {}
        },
        status_addressbook: 0,
      };
      if (mail) add_book_payload.attribute_MAIL_value = mail;
      if (cell) add_book_payload.attribute_CELL_value = cell;
      add_book_payload.registry[this.newResourceIdVal.registry_id] = {};
      const RepoAddrBook = RepositoryFactory.get(this.rep);
      if (this.newResourceIdVal?.address_book_id) {
        promises.push(
          RepoAddrBook.update_without_detach(
            this.newResourceIdVal.address_book_id,
            add_book_payload
          )
        );
      } else {
        promises.push(RepoAddrBook.store(add_book_payload));
      }
      const corr_payload = {
        attribute_ADDR_value: this.form["correspondence"].attribute_ADDR_value,
        attribute_ZIPC_value: this.form["correspondence"].attribute_ZIPC_value,
        registry: {
          // "5350": {}
        },
        status_correspondence: 1,
      };
      const name = this.form["correspondence"].attribute_NAME2_value;
      if (name) corr_payload.attribute_NAME2_value = name;
      if (!this.isForeign) {
        corr_payload["attribute_CITY_value"] =
          this.form["correspondence"].attribute_CITY_value;
      } else {
        corr_payload["attribute_CITY2_value"] =
          this.form["correspondence"].attribute_CITY2_value;
      }
      corr_payload.registry[this.newResourceIdVal.registry_id] = {};
      const RepoCorr = RepositoryFactory.get("correspondence");
      promises.push(
        RepoCorr.update(this.newResourceIdVal.correspondence_id, corr_payload)
      );
      if (
        (this.resourceIds?.registry_id &&
          this.form.registry?.attribute_CHAN_value) ||
        this.form.registry?.attribute_CHAN_value === 0
      ) {
        const RepoReg = RepositoryFactory.get("registry");
        promises.push(
          RepoReg.update_chan(
            this.resourceIds.registry_id,
            this.form.registry.attribute_CHAN_value
          )
        );
      }
      Promise.all(promises)
        .then(() => {
          this.$emit("fetch");
          this.$bvModal.hide(`${this.id}`);
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Contatti Modificati`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetch() {
      this.disableThoughCoordinator = false;
      this.hasPrimaryGroupWithCoordinator = false;
      this.primaryGroupHasLoaded = false;
      this.primaryGroup = "";
      const { registry_id, address_book_id, correspondence_id } =
        this.resourceIds;
      console.log("registry_id", registry_id);
      this.fetchEditForm("registry", registry_id)
        .then(() => {
          console.log("BeForm: ", this.beForm);
          // this.fetchEditForm(this.repository, address_book_id)
          (address_book_id
            ? this.fetchEditForm(this.repository, address_book_id)
            : this.fetchCreateForm(this.repository)
          )
            .then(() => {
              /*
              fetchCorrespondence() {
                console.log("CoR", this.item);
                if (this.item.correspondences && this.item.correspondences.length) {
                  console.log("CoR", this.item);

                  const found = this.item.correspondences.find(
                    (e) => e.status_correspondence.value === 0
                  );
                  if (found) {
                    this.correspondence_id = found.id;
                    return this.fetchEditForm(this.cor, this.correspondence_id);
                  }
                } else {
                  return this.fetchCreateForm(this.cor);
                }
              },
              */
              this.fetchEditForm("correspondence", correspondence_id)
                .then(() => {
                  const country_id = this.optCountries.find(
                    (c) => c.text == this.defaultCountryName
                  ).value;
                  this.form["correspondence"].attribute_NOR_value = country_id;
                  const council_id =
                    this.form.correspondence.attribute_CITY_value;
                  const province_text = council_id
                    ? this.provinceByCouncil()(council_id)
                    : "";
                  let province_id;
                  if (province_text) {
                    province_id = this.optProvinces.find(
                      (c) => c.text === province_text
                    ).value;
                  }
                  if (province_id) {
                    this.form.province = province_id;
                  }
                  this.optCouncils = this.councils()(province_id);
                  const Reporeg = RepositoryFactory.get("registry");
                  let qs = "relations/byRegistryGroup/byCorrespondence";
                  Reporeg.show(registry_id, qs)
                    .then((response) => {
                      const data = response.data.data;
                      console.log("data", data);
                      const primaryGroup = data.registry_groups.filter(
                        (el) => el.pivot.primary.value === "Y"
                      );
                      if (primaryGroup?.[0]?.coordinator?.length) {
                        this.hasPrimaryGroupWithCoordinator = true;
                        this.primaryGroup = `${primaryGroup[0].code} - ${primaryGroup[0].title}`;
                      }
                      this.primaryGroupHasLoaded = true;
                      if (
                        !this.hasPrimaryGroupWithCoordinator &&
                        this.form.registry.attribute_CHAN_value !== 5
                      ) {
                        this.beForm.registry.attribute_CHAN_value.options =
                          this.beForm.registry.attribute_CHAN_value.options.filter(
                            (el) => el.value !== 5
                          );
                        this.disableThoughCoordinator = true;
                      }
                      console.log(
                        "hasPrimaryGroupWithCoordinator",
                        this.hasPrimaryGroupWithCoordinator
                      );
                      this.beForm.registry.attribute_CHAN_value.options.forEach(
                        (el) => console.log(`${el.text} - ${el.value}`)
                      );
                      console.log(
                        "value:",
                        this.form.registry.attribute_CHAN_value
                      );
                      this.isLoading = false;
                      this.formLoaded = true;
                    })
                    .catch((error) => {
                      let errMsg = this.$getErrorMessage(error);
                      this.$showSnackbar({ preset: "error", text: errMsg });
                    })
                    .finally(() => {
                      this.isLoading = false;
                    });
                  /* */
                })
                .catch((error) => {
                  console.error(error);
                });
              /* */
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    onSelectCountry(selected) {
      console.debug("country:", selected);
      this.form.province = null;
      this.form.council = null;
      this.optProvinces = this.provinces()(selected);
      this.optCouncils = [];
      if (!this.isForeign) {
        this.form["correspondence"].attribute_CITY2_value = null;
      } else {
        this.form["correspondence"].attribute_CITY_value = null;
      }
    },
    onSelectProvince(selected) {
      this.optCouncils = [];
      this.form["correspondence"].attribute_CITY_value = null;
      console.debug("province:", selected);
      this.optCouncils = this.councils()(selected);
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "councils",
      "provinceByCouncil",
      "countryByProvince",
    ]),
    ...mapGetters("auth", {
      getCooperators: "cooperators",
    }),
  },
  // created() {
  //   this.initDefaultFormValues();
  // },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("councils")) {
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    }
    if (!this.hasLoadedResource("provinces")) {
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    }
    if (!this.hasLoadedResource("countries")) {
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    }
    if (!this.hasLoadedResource("users")) {
      resources.push(
        this.$store
          .dispatch(`${path}users`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    }
    if (!this.hasLoadedResource("cooperators")) {
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.cooperators = this.getCooperators();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.cooperators = this.getCooperators();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          // this.initDefaultFormValues();
          this.isLoading = false;
        });
    } else {
      // this.initDefaultFormValues();
      this.isLoading = false;
    }
    /* */
  },
  // mounted() {
  //   this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
  //     console.log("ciao");
  //     if (modalId !== "addressBookEditModal") return;
  //     // this.isLoading = true;
  //     this.fetchEditForm(this.repository, this.resourceId)
  //       .then(() => {
  //         this.formLoaded = true;
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       })
  //       .finally(() => {
  //         this.isLoading = false;
  //       });
  //   });
  // },
  computed: {
    rep() {
      return this.repository;
    },
    isForeign() {
      if (!this.form["correspondence"].attribute_NOR_value) return false;
      return (
        this.optCountries.find(
          (c) => c.value == this.form["correspondence"].attribute_NOR_value
        ).text !== this.defaultCountryName
      );
    },
  },
  beforeMount() {
    // this.optCountries = this.countries();
    // this.form["correspondence"].attribute_NOR_value = this.optCountries.find(
    //   (c) => c.text == this.defaultCountryName
    // ).value;
    // this.optProvinces = this.provinces()(
    //   this.form["correspondence"].attribute_NOR_value
    // );
    // this.optCouncils = [];
    // this.isLoading = true;
    // this.fetchCreateForm(this.repository)
    //   .then(() => {
    //     this.formLoaded = true;
    //     this.isLoading = false;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  },
};
</script>
<style scoped>
.tableFixHead {
  overflow: auto;
  height: 200px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  text-align: left;
}
th {
  background: #fff;
}

tr {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}
:deep(.modal-dialog .btn-group) {
  flex: 1;
}
:deep(.modal-dialog .btn-group > span) {
  display: block;
  width: 100%;
}
:deep(.modal-dialog .btn-group > span input[type="text"]) {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
:deep(.modal-dialog .btn-group + .btn) {
  height: 32px;
}
</style>
