<template>
  <div class="mt-1 mx-3">
    <quietanzamento> </quietanzamento>
  </div>
</template>

<script>
import Quietanzamento from "@/components/insurance-payments/index.vue";
import { mapActions } from "vuex";

export default {
  props: {},
  components: {
    Quietanzamento,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({ text: "Quietanzamento", path: "", level: 1 });
  },
};
</script>
