<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <!-- <div v-if="ok"> -->
        <p v-html="currentRegistry()"></p>
        <!-- resourceId: {{ resourceId }}<br />
        dbRowData:
        <pre>{{ dbRowData }}</pre> -->
        <b-form-radio-group
          id="radiosGroup"
          v-model="selected"
          :options="options"
          name="group_type"
        >
        </b-form-radio-group>
        <div class="group-info" v-if="selected == '0'">
          <div class="mt-4">
            <b-btn
              variant="lisaweb"
              class=""
              type="button"
              size="sm"
              @click="openModal(groupModalId)"
              >Scegli gruppo</b-btn
            >
          </div>
          <div
            v-if="Object.keys(activeGroup).length"
            class="my-4"
            v-html="rederGroupInfo"
          ></div>
        </div>
        <div class="group-info" v-else-if="selected == '1'">
          <b-row class="mt-4">
            <div class="form-group col-md-6">
              <base-input
                name="code"
                vid="code"
                label="Codice"
                v-model="form[rep].code"
                placeholder="Inserisci un codice gruppo"
                :rules="{ required: true, max: 40 }"
              />
            </div>
            <div class="form-group col-md-6">
              <base-input
                name="title"
                vid="title"
                label="Descrizione"
                v-model="form[rep].title"
                placeholder="Inserisci una descrizione"
                :rules="{ required: true, max: 255 }"
              />
            </div>
          </b-row>
          <!-- <base-input
              vid="title"
              name="title"
              label="Titolo"
              v-model="form.new_method.title"
              :placeholder="`Inserisci un titolo identificativo del metodo`"
              :rules="{ required: true }"
            />
            <base-textarea
              name="description"
              label="Descrizione"
              v-model="form.new_method.description"
              :placeholder="`Inserisci una breve descrizione che ti aiuterà a ricordare maggiori dettagli sul metodo`"
            ></base-textarea> -->
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="">
            <!-- <b-row>
                <div class="form-group col-md-4" v-if="!isLoading">
                  <base-radio
                    :name="beForm[rep].to_collect.label"
                    vid="to_collect"
                    label="Incassabile"
                    v-model="form[rep].to_collect"
                    :options="beForm[rep].to_collect.options"
                  />
                </div>
              </b-row> -->
            <div class="py-2 mb-4">
              <b-button
                :disabled="checkSubmitValidation()"
                @click="handleSubmit(onSubmit)"
                type="button"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
            </div>
          </b-form>
        </validation-observer>
        <!-- </div> -->
        <!-- <div v-else>
          {{ errorMessage }}
        </div> -->
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
    <choose-group-modal
      ref="chooseGroup"
      :activeGroup="activeGroup.id"
      @input="handleChosenGroupModalInput"
    ></choose-group-modal>
  </div>
</template>

<script>
// import FormMixin from "@/mixins/FormMixin";
// import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import ChooseGroupModal from "@/components/modals/chooseGroupModal";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import BaseRadio from "@/components/form/BaseRadio";

export default {
  name: "Premio",
  // mixins: [FormMixin, ShortcutMixin],
  components: {
    // BaseRadio,
    BaseIcon,
    BaseInput,
    ChooseGroupModal,
  },
  data() {
    return {
      isLoading: false,
      repository: "registry_group",
      resource: "registry_group",
      selected: null,
      activeGroup: {},
      options: [
        {
          text: "Aggiungi cliente a gruppo esistente",
          value: 0,
        },
        {
          text: "Crea nuovo gruppo per il cliente",
          value: 1,
        },
      ],
      registry_id: null,
      groupModalId: "chooseGroupModal",
      form: {
        registry_group: {
          code: null,
          title: null,
        },
      },
    };
  },
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    // this.isLoading = true;
    // this.fetchEditForm(this.repository, this.resourceId)
    //   .then(() => {
    //     this.isLoading = false;
    //   })
    //   .catch((error) => {
    //     let errMsg = this.$getErrorMessage(error);
    //     this.$showSnackbar({
    //       preset: "error",
    //       text: `${errMsg}`,
    //     });
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  },
  methods: {
    checkSubmitValidation() {
      if (this.selected === null) return true;
      if (
        this.selected === 1 &&
        (!this.form[this.rep].code?.length ||
          !this.form[this.rep].title?.length)
      ) {
        return true;
      }
      if (this.selected === 0 && !Object.keys(this.activeGroup).length) {
        return true;
      }
      return false;
    },
    openModal(id) {
      console.log("openModal");
      this.$bvModal.show(id);
    },
    hideModal(id) {
      console.log("hideModal");
      this.$bvModal.hide(id);
    },
    currentRegistry() {
      const cr = this?.dbRowData?.payable?.insurance_policy?.registry?.title;
      if (!cr) {
        this.registry_id = null;
        return "Cliente associato non trovato";
      }
      this.registry_id = this.dbRowData.payable.insurance_policy.registry.id;
      return `Cliente: <strong>${this.dbRowData.payable.insurance_policy.registry.title}</strong>`;
    },
    handleChosenGroupModalInput(group) {
      console.log("group", group);
      this.activeGroup = group;
      // this.activeMethods.push(method.id);
      // console.log("value", method);
      // this.isLoading = true;
      this.hideModal(this.groupModalId);
      // const Repo = RepositoryFactory.get(this.rep);
      // Repo.view_data(method.id)
      //   .then((response) => {
      //     const data = response.data.data;
      //     console.log("response", response.data.data);
      //     this.handleFetchedData(data);
      //     this.$showSnackbar({
      //       preset: "success",
      //       text: `Azione Completata: risultato caricato`,
      //     });
      //   })
      //   .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     this.$showSnackbar({
      //       preset: "error",
      //       text: errMsg,
      //     });
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   });
    },

    onSubmit() {
      this.isLoading = true;
      let registry_group_payload = {};
      const Repo = RepositoryFactory.get(this.rep);
      if (this.selected === 0) {
        registry_group_payload = {
          registry: {},
        };
        registry_group_payload.registry[this.registry_id] = {};
        Repo.pivot_store(
          this.activeGroup.id,
          "registry",
          registry_group_payload
        )
          .then(() => {
            this.$emit("fetch");
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          })
          .finally(() => {
            this.isLoading = false;
            this.hideModal(this.groupModalId);
            this.hideModal("modal");
          });
      } else {
        registry_group_payload = {
          code: this.form[this.rep].code,
          title: this.form[this.rep].title,
        };
        Repo.store(registry_group_payload)
          .then((response) => {
            const data = response.data.data;
            console.log("data", data);
            // this.$emit("fetch");
            /* */
            let inner_registry_group_payload = {
              registry: {},
            };
            inner_registry_group_payload.registry[this.registry_id] = {};
            Repo.pivot_store(data.id, "registry", inner_registry_group_payload)
              .then(() => {
                this.$emit("fetch");
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: `${errMsg}`,
                });
              })
              .finally(() => {
                this.isLoading = false;
                this.hideModal(this.groupModalId);
                this.hideModal("modal");
              });
            /* */
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      }
      /*
      store(payload) {
        // save the item
        {
          title: "",
          code: ""
        }
        return Repository.post(`${resource}`, payload);
      },
      pivot_store(id, relation, payload) {
        // store the related data, where payload = {
        //   "registry": {
        //     "1": {},
        //   }
        // }
        // (se mando in POST 1 solo record, insert or update solo su quello)
        return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
      },
      */
      // this.update(this.repository, this.resourceId)
      //   .then(() => {
      //     this.$emit("fetch");
      //   })
      //   .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     this.$showSnackbar({
      //       preset: "error",
      //       text: `${errMsg}`,
      //     });
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   });
    },
  },
  computed: {
    rederGroupInfo() {
      return `
        <p>Gruppo Selezionato:</p>
        <div class="custom-grid-wrapper">
          <span>Codice</span><strong>${this?.activeGroup?.code ?? "-"}</strong>
          <span>Descrizione</span><strong>${
            this?.activeGroup?.title ?? "-"
          }</strong>
          <span>Capogruppo</span><strong>${
            this?.activeGroup?.coordinator &&
            this?.activeGroup?.coordinator.length
              ? this.activeGroup.coordinator[0].title
              : "-"
          }</strong>
        </div>
      `;
    },
    rep() {
      return this.repository;
    },
  },
};
</script>
