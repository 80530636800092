<template>
  <div class="mt-2">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-row>
                <div class="form-group col-md-6">
                  <base-textarea
                    vid="comment"
                    :name="beForm[rep].comment.label"
                    :label="getDictionary('comment', rep)"
                    v-model="form[rep].comment"
                    rows="4"
                    maxrows="4"
                    placeholder="Inserire un commento"
                  />
                  <!-- <base-textarea
                    name="comment"
                    vid="comment"
                    label="Commento"
                    v-model="form[rep].comment"
                    rows="4"
                    maxrows="4"
                    placeholder="Inserire un commento"
                  /> -->
                </div>
              </b-row>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  v-if="form[rep].comment"
                  class="btn-quick ml-1"
                  size="sm"
                  text="Button"
                  variant="lisaweb"
                  @click="deleteComment()"
                  title="Elimina Commento"
                  ><b-icon icon="trash"></b-icon
                ></b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseTextarea from "@/components/form/BaseTextarea";
// import { mapGetters } from "vuex";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
// import { toLocaleCurrency } from "@/utils/strings";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
export default {
  name: "Comment",
  mixins: [FormMixin, ShortcutMixin],
  components: {
    // BaseSelect,
    // BaseInput,
    // BaseRadio,
    // BaseDatepicker,
    // BaseDynamicInput,
    BaseIcon,
    BaseTextarea,
  },
  data() {
    return {
      repository: "insurance_payment",
      form: {
        insurance_payment: {
          comment: null,
        },
      },
    };
  },
  props: {
    resourceId: Number,
  },
  created() {
    this.isLoading = true;
    // const cr = this.fetchEditForm(this.repository, this.id);
    // Promise.all([cr]).then(() => {
    //   // this.initDefaultFormValues();
    //   this.isLoading = false;
    // });
    this.fetchEditForm(this.repository, this.resourceId)
      .then(() => {
        // this.initDefaultFormValues();
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    initDefaultFormValues() {},
    onSubmit() {
      //Add overlay during edit operation
      this.isLoading = true;
      this.update(this.repository, this.resourceId)
        .then(() => {
          // fetch table data
          this.$emit("fetch");
          this.$parent.$emit("fetch");
          this.$bvModal.hide("modal");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteComment() {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.repository);
      Repo.comment_destroy(this.resourceId)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Commento eliminato`,
          });
          // fetch table data
          this.$emit("fetch");
          this.$parent.$emit("fetch");
          this.$bvModal.hide("modal");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
