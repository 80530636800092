import { render, staticRenderFns } from "./InsurancePayments.vue?vue&type=template&id=542aeebd&scoped=true&"
import script from "./InsurancePayments.vue?vue&type=script&lang=js&"
export * from "./InsurancePayments.vue?vue&type=script&lang=js&"
import style0 from "./InsurancePayments.vue?vue&type=style&index=0&id=542aeebd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542aeebd",
  null
  
)

export default component.exports