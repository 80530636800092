<template>
  <b-modal
    id="chooseGroupModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people-fill"></b-icon>
        <span>Seleziona un gruppo</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="lisaweb">Chiudi</b-btn>
      </b-form-group>
    </template>
    <!-- <analysis-results-table
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      noActions
      :ref="tableRef"
      @autofetch="autofetch"
      includeRowSelector
      @rowSelector="onModalSelector"
      :parentActiveMethods="parentActiveMethods"
    ></analysis-results-table> -->
    <div class="mx-1" v-if="canVerb(resource, 'index')">
      <!-- <b-row>
        <b-col align="left">
          <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Filtra
          </b-button-group>
        </b-col>
        <b-col align="right">
          <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
          >
            <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea
          </b-button>
        </b-col>
      </b-row> -->

      <!-- <b-collapse visible id="collapse-1">
        <div class="mt-2" id="collapse-1"> -->
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="group"
                v-model="filter.byAttribute.code"
                type="text"
                label="Codice Gruppo"
                placeholder="Inserisci un gruppo"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="titolo"
                v-model="filter.byAttribute.title"
                type="text"
                label="Descrizione"
                placeholder="Inserisci un titolo"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca
              </b-button>
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset
              </b-button>
            </div>
          </b-row>
        </b-card>
      </b-form>
      <!-- </div>
      </b-collapse> -->

      <!-- <button-group-table
      @csv="onExport('csv')"
      :options="{
        CSV: { enabled: true },
      }"
    ></button-group-table> -->

      <table-group
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :filterOn="{
          byRelations: ['byRegistry'],
        }"
        :ref="tableRef"
        noActions
        includeRowSelector
        @autofetch="autofetch"
        @rowSelector="onModalSelector"
        :parentActiveGroup="parentActiveGroup"
      ></table-group>
    </div>
    <div v-else class="mt-3">
      <b-icon icon="info-circle" scale="1.00"></b-icon>
      Non hai i permessi
    </div>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import TableGroup from "@/components/tables/InsurancePaymentsRegistryGroups";
// import AnalysisResultsTable from "@/components/tables/AnalysisResultsTable.vue";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      isLoading: false,
      repository: "registry_group",
      resource: "registry_groups",
      filterName: "filterRegistryGroup",
      filter: this.initFilter(),
      items: [],
      tableRef: "RegistryGroupTableRef",
      fields: [
        {
          key: "rowSelector",
          label: "Azioni",
          thStyle: {
            width: "5px",
          },
        },
        {
          key: "code",
          value: "code",
          label: this.getDictionary("code", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.code",
        },
        {
          key: "title",
          value: "title",
          label: this.getDictionary("title", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "coordinator",
          value: "coordinator",
          label: this.getDictionary("is_coordinator", "registry_group"),
          sortable: false,
        },
        {
          key: "anagrafica",
          value: (item) =>
            item.registries
              ? item.registries
                  .map((e) => {
                    return e.status_registry.value === 1
                      ? e.attributables.CNAM
                      : e.attributables.NAME + " " + e.attributables.SURN;
                  })
                  .join(",")
              : "",
          label: this.getDictionary("registry"),
          sortable: false,
        },
      ],
      parentActiveGroup: null,
    };
  },
  components: {
    TableGroup,
    BaseInput,
    // AnalysisResultsTable,
  },
  methods: {
    autofetch() {
      this.parentActiveGroup = this.activeGroup;
      this.$refs[this.tableRef].fetch();
    },
    onModalSelector(group) {
      this.$emit("input", group);
      this.parentActiveGroup = group?.id;
    },
    initFilter() {
      let init = {
        byAttribute: {
          code: null,
          title: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "attributables") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      // this.exportUrl = this.$refs[this.tableRef].loadFilter(
      //   this.$refs[this.tableRef].filterName
      // );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  props: {
    activeGroup: { type: Number },
    //   subject: {
    //     type: String,
    //   },
    //   email: {
    //     type: String,
    //   },
  },
};
</script>

<style scoped>
.subject {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
